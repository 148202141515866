.about-container {
  height: 90vh;
}

.introduction-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem 0;
  width: 100%;
}

.introduction-container h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.introduction-container p {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.experience-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 2rem 0;
  width: 100%;
  height: 100px;
}

.work-experience-container {
    border: 1px solid black;
    padding: 1rem;
    margin: 1rem;

}

.education-container {
    border: 1px solid black;
    padding: 1rem;
    margin: 1rem;
}

.skills-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem 0;
  width: 100%;
}

.hobbies-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 2rem 0;
  width: 100%;
}

.fun-fact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem 0;
  width: 100%;
}
