.footer-container {
  background-color: dodgerblue;
  width: 100%;
  height: 4rem;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e0e0e0;
  font-size: 1.2rem;
}
