.layout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.navigation-component {
  width: 100%;
}

.hero-container {
  background-color: #f5f5f5;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero-text {
  font-size: 2rem;
  font-weight: 500;
  color: #333;
}

.hero-text p {
  font-size: 1.5rem;
  font-weight: 400;
  color: #333;
}

.hero-text span {
  color: rebeccapurple;
}

.technologies-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem 0;
}

.technologies {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.technology-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem;
  margin: 1rem;
  border-radius: 5px;
  width: 200px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.technology-card:hover {
  transform: scale(1.2);
}

.technology-icon-container i {
  font-size: 3rem;
  color: rebeccapurple;
}

.featured-projects-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.featured-projects {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 2rem 0;
  width: 100%;
}

.project {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem;
  margin: 1rem;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}
/* MEDIA QUERY - mobile <320px */
@media screen and (max-width: 320px) {
  .home-container {
    background-color: red;
    width: 100%;
  }

  .hero-container {
  }

  .technologies-container {
  }

  .featured-projects {
  }
}

/* MEDIA QUERY - mobile >320px */
@media screen and (min-width: 320px) {
  .home-container {
    background-color: yellow;
  }

  .hero-container {
  }

  .technologies-container {
  }

  .featured-projects {
  }
}

/* MEDIA QUERY - tablet >768px */
@media screen and (min-width: 768px) {
  .home-container {
    background-color: green;
  }

  .hero-container {
  }

  .technologies-container {
  }

  .featured-projects {
  }
}

/* MEDIA QUERY - desktop >1200px */
@media screen and (min-width: 1200px) {
  .home-container {
    background-color: blue;
  }

  .hero-container {
  }

  .technologies-container {
  }

  .featured-projects {
  }
}
