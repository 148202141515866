.navigation-component {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: dodgerblue;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 100;
  font-size: 2rem;
}

.navigation-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-container a {
  text-decoration: none;
  color: #fff; /* Add color style */
  font-size: 2.5rem;
}

.logo {
  margin-left: 20px;
  color: #fff;
}

nav {
  display: flex;
}

.nav-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-menu-mobile {
  display: none;
}

.nav-item {
  padding: 0 15px;
  font-size: 1.5rem;
}

.nav-item a {
  color: #fff;
  text-decoration: none;
}

.nav-icon {
  display: none;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .logo-container a {
    font-size: 1.5rem;
  }

  .nav-menu {
    display: none;
  }

  .nav-menu-mobile {
    display: block;
    position: absolute;
    top: 60px;
    right: 0; /* Change left to right */
    background-color: dodgerblue;
    list-style: none;
    min-width: 200px;
    width: 40%;
    padding: 10px 0;
    border-radius: 0 0 0 20px;
  }

  .nav-icon {
    display: block;
    position: absolute; /* Add position absolute */
    top: 15px; /* Adjust top position */
    right: 20px; /* Add right position */
  }
}
